import fileServiceModule from "./fileService";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import axios from "axios";
import config from "../../config"


import * as Validator from "validatorjs";
import { toRefs, reactive, ref } from "vue";

const fileService = fileServiceModule();

const state = reactive({
    conversations: [],
    topGroups: [],
    dummyMessages: {},
    cachedConversations: {},
    readDates: {},
    initialized: false

});

const unsubscribeHandles = [];


export default function () {

    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        state.conversations = [];
        state.topGroups = [];
        state.readDates = [];
        state.dummyMessages = {};
    }

    function getConversation(conversationId) {

        return new Promise((resolve) => {
            let isResolved = false;

            if (!state.cachedConversations[conversationId]) {

                if (!conversationId) {
                    return resolve(null)
                }
                if (conversationId.includes(':')) {
                    return resolve(null)
                }
                try {
                    console.log('getConversation conversationId', conversationId)
                    let conversationUnsubscribeHandle = firebase
                        .firestore()
                        .collection("conversations")
                        .doc(conversationId)
                        .onSnapshot((snapshot) => {
                            let data = snapshot.data()
                            data.id = conversationId;

                            console.log('cached conversation changed', conversationId)

                            if (!state.cachedConversations[conversationId]) {
                                state.cachedConversations[conversationId] = {}
                            }
                            console.log('keys of changed cached conversation', conversationId, Object.keys(state.cachedConversations[conversationId]))
                            let processedKeys = [];
                            for (let key of Object.keys(data)) {
                                state.cachedConversations[conversationId][key] = data[key];
                                processedKeys.push(key);
                            }
                            for (let key of Object.keys(state.cachedConversations[conversationId])) {
                                if (!processedKeys.includes(key)) {
                                    console.log('removing key', key)
                                    state.cachedConversations[conversationId][key] = undefined;
                                }
                            }

                            // state.cachedConversations[conversationId] = ref(data);
                            if (!isResolved) {
                                isResolved = true;
                                resolve(state.cachedConversations[conversationId] ? state.cachedConversations[conversationId] : null)
                            }
                        });
                    unsubscribeHandles.push(conversationUnsubscribeHandle);
                } catch (error) {
                    return resolve(null);
                }

            } else {
                return resolve(state.cachedConversations[conversationId] ? state.cachedConversations[conversationId] : null);
            }

        });

    }



    async function loadChat() {

        //clear any existing subscriptions
        if (unsubscribeHandles.length > 0) {
            await teardown()
        }

        let loadedParts = 0;
        let numberOfPartsToLoad = 2;

        if (!firebase.auth().currentUser || !firebase.auth().currentUser.uid) {
            return;
        }

        let conversationUnsubscribeHandle = await firebase
            .firestore()
            .collection("conversations")
            .where('participants', 'array-contains', firebase.auth().currentUser.uid)
            .where('processed', '==', true)
            .orderBy("dateOfLastPost", "desc")
            .onSnapshot((docRef) => {
                docRef.docChanges().forEach((change) => {
                    const { newIndex, oldIndex, doc, type } = change;
                    let data = doc.data();
                    data.id = doc.id;

                    if (!state.cachedConversations[doc.id]) {
                        state.cachedConversations[doc.id] = {}
                    }
                    
                    let processedKeys = [];
                    for (let key of Object.keys(data)) {
                        state.cachedConversations[doc.id][key] = data[key];
                        processedKeys.push(key);
                    }
                    for (let key of Object.keys(state.cachedConversations[doc.id])) {
                        if (!processedKeys.includes(key)) {
                            console.log('removing key', key)
                            state.cachedConversations[doc.id][key] = undefined;
                        }
                    }


                    if (type === "added") {
                        state.conversations.splice(newIndex, 0, data);
                        // if we want to handle references we would do it here
                    } else if (type === "modified") {
                        // remove the old one first
                        state.conversations.splice(oldIndex, 1);
                        // if we want to handle references we would have to unsubscribe
                        // from old references' listeners and subscribe to the new ones
                        //data.processingMembers = false;
                        state.conversations.splice(newIndex, 0, data);

                    } else if (type === "removed") {
                        state.conversations.splice(oldIndex, 1);
                        // if we want to handle references we need to unsubscribe
                        // from old references
                    }

                });
                loadedParts++;
                if (loadedParts >= numberOfPartsToLoad) {
                    state.initialized = true;
                }
            });

        unsubscribeHandles.push(conversationUnsubscribeHandle);


        let openChatsUnsubscribeHandle = await firebase
            .firestore()
            .collection("conversations")
            .where('public', '==', true)
            .where('processed', '==', true)
            .orderBy("dateOfLastPost", "desc")
            .limit(5)
            .onSnapshot((docRef) => {
                docRef.docChanges().forEach((change) => {
                    const { newIndex, oldIndex, doc, type } = change;
                    let data = doc.data();
                    data.id = doc.id;
                    if (type === "added") {
                        state.topGroups.splice(newIndex, 0, data);
                        // if we want to handle references we would do it here
                    } else if (type === "modified") {
                        // remove the old one first
                        state.topGroups.splice(oldIndex, 1);
                        // if we want to handle references we would have to unsubscribe
                        // from old references' listeners and subscribe to the new ones
                        //data.processingMembers = false;
                        state.topGroups.splice(newIndex, 0, data);

                    } else if (type === "removed") {
                        state.topGroups.splice(oldIndex, 1);
                        // if we want to handle references we need to unsubscribe
                        // from old references
                    }

                });
                loadedParts++;
                if (loadedParts >= numberOfPartsToLoad) {
                    state.initialized = true;
                }
            });

        unsubscribeHandles.push(openChatsUnsubscribeHandle);


        let readDateUnsubscribeHandle = await firebase
            .firestore()
            .collection("conversationsReadDates")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                state.readDates = snapshot.data();
            });
        unsubscribeHandles.push(readDateUnsubscribeHandle);

    }

    function addDummyMessage(data) {

        
        if(!data.id && !data.conversationId){
            console.log('addDummyMessage: invalid data', data);
            return;
        }


        if (!state.dummyMessages) {
            state.dummyMessages = {}
        }
        if (!state.dummyMessages[data.conversationId]) {
            state.dummyMessages[data.conversationId] = [];
        }
        state.dummyMessages[data.conversationId].push(data);
    }

    function removeDummyMessage(conversationId, messageId) {
        if (!state.dummyMessages || state.dummyMessages.length < 1) {
            console.log('imhere wher i shouldnt be')
            return
        }
        if (!state.dummyMessages[conversationId]) {
            //console.log('remove dummy conversation doesnt exist')
            return
        }

        let copied = state.dummyMessages[conversationId].slice();
        for (let message of copied) {
            if (message.id == messageId) {
                copied.splice(copied.indexOf(message), 1);
                state.dummyMessages[conversationId] = copied;
                console.log('removing dummy message', messageId)
                return;
            }
        }
    }


    async function sendMessage(data, progressCallback) {
        if (!firebase.auth().currentUser) {
            return;
        }


        let messageRules = {

        }
        let validation = new Validator(data, messageRules);
        if (validation.fails()) {
            throw new Error({
                type: 'validation',
                errors: validation.errors.errors
            })
        }

        addDummyMessage(data);

        if (data.picture) {
            data.picture = await fileService.uploadFile(data.picture, progressCallback);
        }

        if (data.video) {
            data.video = await fileService.uploadFile(data.video, progressCallback);
        }
        if (data.audio) {
            data.audio = await fileService.uploadFile(data.audio, progressCallback);
        }

        data.senderUid = firebase.auth().currentUser.uid;


        await firebase.firestore().collection("chat-messages").doc(data.id).set(data);


        // let response = await axios.post(config.apiUrl + "/api/chat/message", data, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     }
        // });
        // if (!response || !response.data) {
        //     throw new Error();
        // }

    }

    async function deleteMessage(messageId) {

        if (!messageId) {
            return;
        }
        
        if (!firebase.auth().currentUser) {
            return;
        }

        let token = await firebase.auth().currentUser.getIdToken();

        let response = await axios.delete(config.apiUrl + "/api/chat/message/" + messageId, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (!response || !response.data) {
            throw new Error();
        }

    }






    async function markConversationAsRead(conversationId) {
        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('markConversationAsRead: no conversation')
            return
        }

        // let isManagedLocally = conversation.public;

        // await firebase
        //     .firestore()
        //     .collection("conversationsReadDates")
        //     .doc(firebase.auth().currentUser.uid)
        //     .set({ [conversationId]: {date: new Date(), local: isManagedLocally }}, { merge: true });

        await firebase
            .firestore()
            .collection("conversationsReadDates")
            .doc(firebase.auth().currentUser.uid)
            .set({ [conversationId]: new Date() }, { merge: true });
    }

    async function leaveGroup(conversationId) {

        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('leaveGroup: no conversation')
            return
        }

        await firebase
            .firestore()
            .collection("chat-group-management-requests")
            .add({
                uid: firebase.auth().currentUser.uid,
                request: "leave",
                conversationId: conversationId
            });

        conversation.leaving = true;

    }
    async function inviteMembersToGroup(conversationId, uids) {

        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('leaveGroup: no conversation')
            return
        }
        if (!uids || uids.length <= 0) {
            console.log('leaveGroup: no uids')
            return
        }

        await firebase
            .firestore()
            .collection("chat-group-management-requests")
            .add({
                uid: firebase.auth().currentUser.uid,
                request: "invite",
                conversationId: conversationId,
                uids: uids
            });

    }

    async function joinGroup(conversationId) {

        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('joinGroup: no conversation')
            return
        }

        await firebase
            .firestore()
            .collection("chat-group-management-requests")
            .add({
                uid: firebase.auth().currentUser.uid,
                request: "join",
                conversationId: conversationId
            });

        conversation.processingMembers = true;
        conversation.joining = true;

    }
    async function removeGroupParticipants(conversationId, participantUids) {

        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('removeGroupParticipants: no conversation')
            return
        }

        await firebase
            .firestore()
            .collection("chat-group-management-requests")
            .add({
                uid: firebase.auth().currentUser.uid,
                request: "removeParticipants",
                conversationId: conversationId,
                participants: participantUids
            });

        conversation.processingMembers = true;
    }
    async function deleteGroup(conversationId) {

        if (!firebase.auth().currentUser) {
            return;
        }
        let conversation = await getConversation(conversationId);
        if (!conversation) {
            console.log('deleteGroup: no conversation')
            return
        }

        await firebase
            .firestore()
            .collection("chat-group-management-requests")
            .add({
                uid: firebase.auth().currentUser.uid,
                request: "delete",
                conversationId: conversationId
            });
        conversation.aboutToBeDeleted = true;
    }

    async function markAsTyping(conversationId) {
        if (!firebase.auth().currentUser) {
            return;
        }
        await firebase
            .firestore()
            .collection("typing")
            .doc(firebase.auth().currentUser.uid)
            .set({ [conversationId]: new Date() }, { merge: true });
    }

    return {
        ...toRefs(state),
        loadChat,
        markAsTyping,
        sendMessage,
        teardown,
        getConversation,
        markConversationAsRead,
        leaveGroup,
        joinGroup,
        deleteGroup,
        removeGroupParticipants,
        removeDummyMessage,
        deleteMessage,
        inviteMembersToGroup

    };

}
