import { toRefs, reactive, watch } from "vue";
import { Device } from '@capacitor/device';

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();


import notificationService from "./notificationService";
const notifications = notificationService();

const state = reactive({
    initialized: false,
    initializing: false,
    error: null,        
});


export default async function () {
    
    async function getId(){
        return await Device.getId();
    }
    async function getInfo(){
        return await Device.getInfo();
    }
    async function getBatteryInfo(){
        return await Device.getBatteryInfo();
    }
    async function getLanguageCode(){
        return await Device.getLanguageCode();
        
    }
    async function getDeviceIdForUid(uid){
        let physicalID =  await getId();
        return physicalID.uuid + '' + uid;        
    }
    firebase.auth().currentUser

    async function updateDeviceForUser(){       
        
        try {
            
        
        if(!firebase.auth().currentUser){
            console.log('updateDeviceForUser without user')
            return;
        }
        let deviceId = await getDeviceIdForUid(firebase.auth().currentUser.uid);
        let info = await getInfo()
        let language = await getLanguageCode();
        
        let data = {
            deviceId: deviceId,
            language: language ? language.value : null,
            uid: firebase.auth().currentUser.uid,
            dateUpdated: new Date(),
            info: info,
            token: notifications.token ? notifications.token.value : null
        }
        console.log('device data', data)

        const resp = await firebase
            .firestore()
            .collection("devices")
            .doc(data.deviceId)
            .set(data, { merge: true });

        } catch (error) {
            console.log('error @ updateDeviceForUser', error);    
        }
    }
    async function removeDeviceOfUser(user){
        let deviceId = await getDeviceIdForUid(user.uid);
        console.log('removeDevice', deviceId, user);
        try {
            const resp = await firebase
            .firestore()
            .collection("devices")
            .doc(deviceId)
            .delete();
        } catch (error) {
            console.log('error@removeDevice', error);
        }
    }


    if(!state.initialized && !state.initializing){
        state.initializing = true;
        // console.log('initializing deviceService');
        
        watch(auth.user, async  (user,prevUser) => {
             console.log('deviceService user changed', user);
            if(user){
                updateDeviceForUser();
            }
            if(!user){                
                removeDeviceOfUser(prevUser);
            }
        })
        
        watch(notifications.token, (token,prevToken) => {
            console.log('push notifiction token changed', token);
            updateDeviceForUser();
        })

        if(auth.user){
            updateDeviceForUser();
        }
        state.initialized = true
        state.initializing = false
    }





    return {
        ...toRefs(state),
        getId,
        getInfo,
        getBatteryInfo,
        getLanguageCode,
        updateDeviceForUser
    }


}