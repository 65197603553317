<template>
  <ion-item lines="full" @click="clicked" v-if="loadedUser">
    <user-avatar v-if="loadedUser" slot="start" class="profile-avatar" :user="loadedUser" :show-status="true" :zoom="false"></user-avatar>
    <ion-label>
      <h2 v-if="loadedUser" v-text="loadedUser.name"></h2>
      <h3 v-if="loadedUser && loadedUser.bio" v-text="loadedUser.bio"></h3>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button class="view-button" v-if="!showCheckbox && !disabled">
        <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
      </ion-button>
      <ion-button class="view-button" v-if="showCheckbox && !disabled">
        <ion-checkbox slot="end" @update:modelValue="isChecked = $event" :modelValue="isChecked"> </ion-checkbox>
      </ion-button>
    </ion-buttons>    
  </ion-item>
</template>

<script >
import { IonIcon, IonAvatar, IonLabel, IonItem, IonButton, IonButtons, IonCheckbox } from "@ionic/vue";

import { chatbubbleOutline, chevronForwardOutline } from "ionicons/icons";
import UserAvatar from "./UserAvatar";
import userService from "../composables/userService";

export default {
  name: "ChatSuggestionItem",
  props: ["user", "uid", "showCheckbox", "modelValue", "disabled"],
  data: function () {
    return {
      loadedUser: null,
      isChecked: false,
    };
  },
  components: {
    IonIcon,
    IonAvatar,
    IonLabel,
    IonItem,
    IonButton,
    IonButtons,
    UserAvatar,
    IonCheckbox,
  },
    emits: ['update:modelValue'],

  watch:{
    modelValue(){
      if(this.isChecked != this.modelValue){
        this.isChecked = this.modelValue
      }
    },
    isChecked(){      
        this.$emit('update:modelValue', this.isChecked);
    }
  },
  async mounted() {
    if (this.user) {
      this.loadedUser = this.user;
    } else if (this.uid) {
      this.loadedUser = await this.userService.getUser(this.uid);
    }
    this.isChecked = this.modelValue
  },
  methods: {
    loadUser() {},
    clicked(e) {
      if (!this.disabled && this.showCheckbox && e.target.localName != "ion-checkbox") {
        this.isChecked = !this.isChecked;
      }
    },
    updateChecked(e) {
      console.log(this.updateChecked, e);
    },
  },
  setup() {
    let userServiceInstance = userService();
    return {
      userService: userServiceInstance,
      chatbubbleOutline,
      chevronForwardOutline,
    };
  },
};
</script>
<style scoped>
.profile-avatar {
  position: relative;
}
.online-marker {
  background-color: var(--ion-color-success);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.5rem;
  position: absolute;
  right: 0%;
  bottom: 0%;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
</style>>
