import fileServiceModule from "./fileService";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();

import * as Validator from "validatorjs";

import { toRefs, reactive, watch } from "vue";
const fileService = fileServiceModule();

const state = reactive({
    mediaUrls: {},
    initializing: false,
    initialized: false


});

const unsubscribeHandles = [];


export default function () {

    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        state.mediaUrls = [];

    }

    async function getMediaUrl(mediaId) {
        let url = null;
        
        if (!mediaId) {
            return null;
        }


        if(!state.mediaUrls[mediaId]){
            

            try {
                url = await firebase
                    .storage()
                    .ref(mediaId)
                    .getDownloadURL();
            } catch (error) {
                //console.log("error", error);
            }
            if (url) {
                state.mediaUrls[mediaId] = url;
            }
        }
        return state.mediaUrls[mediaId] ? state.mediaUrls[mediaId] : null;

    }
    if (!state.initialized && !state.initializing) {

        state.initializing = true;


        watch(auth.user, async (user, prevUser) => {
            // console.log('user changed', user);
            teardown();

        })



        state.initialized = true
        state.initializing = false



    }


    return {
        ...toRefs(state),
        teardown,
        getMediaUrl
    };

}
