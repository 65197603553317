import { toRefs, reactive } from "vue";
import { Capacitor } from '@capacitor/core';


import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import firebaseConfig from "../../firebase-config.dev.js";

var firebaseApp = null
import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence,connectAuthEmulator } from 'firebase/auth';


function whichAuth(app) {
    let auth

    return auth
}

export default function () {
    var auth = null;



    if (!firebaseApp) {

        firebaseApp = firebase.initializeApp(firebaseConfig);

        auth = initializeAuth(firebaseApp, {
            persistence: [indexedDBLocalPersistence, browserLocalPersistence],
            /* popupRedirectResolver: undefined */
        });
        
        // if (Capacitor.getPlatform() === 'web' && location.hostname === 'localhost') {
        //     console.log('using firebase emulators')
        //     connectAuthEmulator(auth, "http://localhost:9099");
        //     firebase.firestore().useEmulator('localhost', 8080);
        //     firebase.functions().useEmulator("localhost", 5001);            
        //     firebase.auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
        //     firebase.storage().useEmulator("localhost", 9199);
        // }
    }

    return firebaseApp;
}