<template  >
  <ion-content :fullscreen="true" class="">
    <ion-icon
      :icon="closeCircleSharp"
      class="close-button"
      @click="dismiss"
    ></ion-icon>

    <div
      class="image-container"
      :style="`background-image:url('${image}')`"
    ></div>
  </ion-content>
</template>

<script >
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonButton,
  IonLabel,
  IonIcon,
  IonModal,
  IonInput,
  IonTextarea,
  modalController,
  IonList,
  IonButtons,
  IonItem,
} from "@ionic/vue";

import { closeCircleSharp } from "ionicons/icons";

export default {
  name: "ImageViewModal",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonAvatar,
    IonButton,
    IonLabel,
    IonIcon,
    IonModal,
    IonInput,
    IonTextarea,
    IonList,
    IonButtons,
    IonItem,
  },
  props: ["image"],
  data() {
    return {
      resizeObserver: null
    };
  },
  setup() {
    return {
      closeCircleSharp,
    };
  },
  watch: {},
  unmounted(){
    if(this.resizeObserver){
      this.resizeObserver.disconnect();
    }
  },

  async mounted() {
    this.observeHeight();
    this.$nextTick( () => {
             
    });
  },
  
  methods: {
    test() {
      console.log("ionModalDidPresent imageView");
    },
    observeHeight(){
        // this.resizeObserver = new ResizeObserver(() => {
        //   console.log("Size changed",this.$el.offsetHeight);
        // });
        // this.resizeObserver.observe(this.$el);
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>
<style scoped>
ion-content {
  --background: #000;
}

.image-container {
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.close-button {
  cursor: pointer;
  font-size: 3rem;
  color: rgba(100, 100, 100, 0.9);
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 50;
}
</style>


<style>
@media (max-width: 768px) {
  .profile-image-modal.sc-ion-modal-md-h {
    --width: 80vw;
    --height: 80vw;
    --max-height: 80%;
  }
}
</style>
