<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Chats</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">




      <conversation-list-item
        v-for="conversation of visibleConversations"
        :key="conversation.id"
        :conversation="conversation"
      ></conversation-list-item>
      <div class="no-chats-notice" v-if="loaded && (!visibleConversations || visibleConversations.length < 1)">
        <ion-text>Start following some friends and start chatting...</ion-text>
          <ion-button  @click="search"
            ><ion-icon slot="start" :icon="searchOutline" class=""></ion-icon>Search People...</ion-button
          >
      </div>
    </ion-content>

    <ion-fab horizontal="end" vertical="bottom" slot="fixed" class="content-add-button" @click="showAddChatModal">
      <ion-fab-button title="Add Content">
        <ion-icon :icon="chatbubbleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script >
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFab, IonFabButton, IonIcon, IonText,IonButton, modalController } from "@ionic/vue";

import { addOutline, chatbubbleOutline, searchOutline } from "ionicons/icons";
import chatService from "../composables/chatService";
import ConversationListItem from "./ConversationListItem";

import AddChatModal from "./AddChatModal";

export default {
  name: "ChatTab",
  components: {IonHeader, IonToolbar, IonTitle, IonContent, IonPage, ConversationListItem, IonFab, IonFabButton, IonText,IonButton, IonIcon },
  watch: {},
  data(){
    return{
      loaded: false
    }
  },
  setup() {
    let chat = chatService();

    return {
      chat,
      conversations: chat.conversations,
      chatbubbleOutline,searchOutline
    };
  },
  mounted(){
    setTimeout(() => {
      this.loaded = true;
    }, 500)
  },
  computed: {
    visibleConversations() {
      return this.conversations ? this.conversations.filter((conversation) => !conversation.leaving && !conversation.aboutToBeDeleted) : [];
    },
  },
  methods: {
    async showAddChatModal() {
      const modal = await modalController.create({
        component: AddChatModal,
        componentProps: {},
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
    },
    search() {
      this.$router.push({
        name: "search",
        replace: true,
      });      
    },
  },
  
};
</script>
<style scoped>
.content-add-button {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.no-chats-notice{
  text-align: center;
  padding: 1rem;
}

.no-chats-notice ion-text{
   line-height: 1.4;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  color: #444;
  font-size: 1.15rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
</style>