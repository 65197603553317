import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import deviceService from "./composables/deviceService";

import firebaseService from "./composables/firebaseService";


import notificationService from "./composables/notificationService";



import authenticationService from "./composables/authenticationService";



//check if there is a firebase user before continuing
const auth = authenticationService();


auth.authCheck().then(() => {

  const device = deviceService();
  const firebase = firebaseService();
  const notifications = notificationService();
  notifications.setup(
    (notification) => {
      const data = notification.notification.data;
      if (data.path) {
        router.push({ path: data.path });
      }
    }
  );

  const app = createApp(App)
    .use(IonicVue)
    .use(router);


  app.mixin(
    {
      computed: {
        loggedInUser() {
          return auth.user ? auth.user.value : null;
        },
        firebase() {
          return firebase
        },
        device() {
          return device
        }
      },
    }
  )

  // make v-focus usable in all components
  app.directive('focus', {



  })
  console.log('imhere');

  app.directive('longpress', {
    created: function (el, binding, vNode) {
      if (typeof binding.value !== 'function') {
        let warn = `[longpress:] provided expression is not a function, but has to be`
        console.warn(warn)
      }

      let pressTimer = null
      let originalEvent = null;

      let start = (e) => {        
        if (e.type === 'click' && e.button !== 0) {
          
          return;
        }
        originalEvent = e;

        if (pressTimer === null) {
          pressTimer = setTimeout(() => {
            // Run function
            handler(e)
          }, 400)
        }
      }

      // Cancel Timeout
      let cancel = (e) => {        
        // Check if timer has a value or not
        if (pressTimer !== null) {
          clearTimeout(pressTimer)
          pressTimer = null
        }
      }
      // Run Function
      const handler = (e) => {
        console.log('originalEvent', originalEvent, e)
        binding.value(e)
      }

      // Add Event listeners
      el.addEventListener("mousedown", start);
      el.addEventListener("touchstart", start);
      // Cancel timeouts if this events happen
      el.addEventListener("click", cancel);
      el.addEventListener("mouseout", cancel);
      el.addEventListener("touchend", cancel);
      el.addEventListener("touchcancel", cancel);
    }
  })

  router.isReady().then(() => {
    app.mount('#app');
  });
}, 1000);
