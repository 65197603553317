import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsView from './views/TabsView.vue'
import ChatTabView from './views/ChatTabView.vue'

import authenticationService from "./composables/authenticationService";
const auth = authenticationService();

const routes = [
  {
    path: '/',
    redirect: '/startpage'
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/invitation/:invitationId/:token",
    name: "invitation",
    component: () => import("@/views/InvitationPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("@/views/RegistrationPage.vue"),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyPage.vue')
  },      
  {
    path: '/termsAndConditions',
    name: 'termsAndConditions',
    component: () => import('@/views/TermsAndConditionsPage.vue')
  },      
  {
    path: '/whitelabel-solution',
    name: 'whitelabelSolution',
    component: () => import('@/views/WhitalabelInfoPage.vue')
  },      
  {
    path: '/',
    component: TabsView,
    children: [
      {
        path: 'startpage',
        name: 'startpage',
        component: () => import('@/views/StartpageTab.vue')
      },
      {
        path: 'startpage/:userId',
        name: 'startpage-userpage',
        component: () => import('@/views/UserPage.vue')
      },          
      {
        path: 'posts/:postId',
        name: 'postpage',
        component: () => import('@/views/PostPage.vue')
      },          
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/NotificationsTab.vue')
      },
      {
        path: 'chat',
        name: 'chat',
        component: ChatTabView
      },
      {
        path: 'chat/:conversationId',
        name: 'chat-conversation',
        component: () => import('@/views/ChatConversationPage.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/FriendsTab.vue'),
      },
      {
        path: '/users/:userId',
        name: 'userpage',
        component: () => import('@/views/UserPage.vue')
      },          
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/ProfileTab.vue')
      },      
      
      {
        path: 'test',
        name: 'test',
        component: () => import('@/views/TestTab.vue')
      },      
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


let allowedForUnAuthenticated = ['login', 'registration', 'forgot-password', 'reset-password', 'privacy', 'termsAndConditions'];

router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach',to.name, to);
  if (auth.user.value && (to.name === 'login')) {
    next({ name: "startpage", replace: true });
  } else if (!auth.user.value && (!allowedForUnAuthenticated.includes(to.name))) {
    // console.log('redirect to login',to.name,encodeURIComponent(to.path), to);
    let destination = to.path;
    next({ name: "login", replace: true, query: { redirect: destination } });
    // next({ name: "login", replace: true});
  } else {
    // console.log('doing ',to.name, to);
    next();
  }
})

export default router
