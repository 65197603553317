import firebaseService from "./firebaseService";
const firebaseApp = firebaseService();
import firebase from 'firebase/compat/app'


import { toRefs, reactive, watch } from "vue";


import authenticationService from "./authenticationService";
const auth = authenticationService();


const state = reactive({
    followers: {},
    following: {},
    blocked: {},
    userDataCache: {},
    initialized: false,
    initializing: false
});

const unsubscribeHandles = [];


export default function () {

    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        state.followers = {};
        state.following = {};
        state.blocked = {};

    }


    async function loadFollowers() {        
        //clear any existing subscriptions
        if (unsubscribeHandles.length > 0) {
            await teardown()
        }

        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            console.log('loadFollowers no user')
            return;
        }

        unsubscribeHandles.push(await firebaseApp
            .firestore()
            .collection("following")
            .doc(firebaseApp.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                let data = snapshot.data();
                state.following = data;
            }));

        unsubscribeHandles.push(await firebaseApp
            .firestore()
            .collection("blocked")
            .doc(firebaseApp.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                let data = snapshot.data();
                state.blocked = data;
            }));

        unsubscribeHandles.push(await firebaseApp
            .firestore()
            .collection("followers")
            .doc(firebaseApp.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                let data = snapshot.data();
                state.followers = data;
            }));

    }

    function followsUser(user) {
        
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {            
            return false;
        }
        
        if (!user && !user.uid) {
            return false;
        }
        
        if (!state.following || !state.following[user.uid]) {
            return false;
        }

        return state.following[user.uid];

    }
    function blockedUser(user) {
        
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return false;
        }

        if(typeof user == 'string'){
            user = {uid: user};
        }

        if (!user && !user.uid) {
            return false;
        }
        
        if (!state.blocked || !state.blocked[user.uid]) {
            return false;
        }
        
        return state.blocked[user.uid];

    }


    async function blockUser(user) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!user || !user.uid) {
            console.log("no user given")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("blocked")
            .doc(firebaseApp.auth().currentUser.uid).
            set({ [user.uid]: true }, { merge: true })

    }

    

    async function unblockUser(user) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!user || !user.uid) {
            console.log("no user given")
            return;
        }

        if (firebaseApp.auth().currentUser.uid == user.uid) {
            console.log("cant block myself")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("blocked")
            .doc(firebaseApp.auth().currentUser.uid)
            .update({
                [user.uid]: firebase.firestore.FieldValue.delete()
            });
    }


    async function unfollowUser(user) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!user || !user.uid) {
            console.log("no user given")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("following")
            .doc(firebaseApp.auth().currentUser.uid)
            .update({
                [user.uid]: firebase.firestore.FieldValue.delete()
            });
    }



    async function followUser(user) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!user || !user.uid) {
            console.log("no user given")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("following")
            .doc(firebaseApp.auth().currentUser.uid).
            set({ [user.uid]: new Date() }, { merge: true })

    }


    async function getUser(uid) {

        if(!uid){
            return new Promise((resolve) => {resolve(null)});
        }
        if (!state.userDataCache[uid]) {
            const resp = await firebaseApp
                .firestore()
                .collection("users")
                .doc(uid ? uid : null)
                .get();

            if (resp.exists) {
                state.userDataCache[uid] = {
                    ...resp.data(),
                    id: uid
                }
            }
        }
        return state.userDataCache[uid] ? state.userDataCache[uid] : new Promise((resolve) => {resolve(null)});
    }



    if (!state.initialized && !state.initializing) {
        state.initializing = true;
        watch(auth.user, async (user, prevUser) => {            
            if (user) {
                loadFollowers();
            }
        })

        if (auth.user) {
            loadFollowers();
        }
        state.initialized = true
        state.initializing = false
    }



    return {
        ...toRefs(state),
        getUser,
        followsUser,
        unfollowUser,
        followUser,
        blockedUser,
        blockUser,
        unblockUser

    };

}
