const firebaseConfig = {
  apiKey: "AIzaSyDcbBxA6ahT6Y13I69xk7gaM6b8AjlwRvk",
  authDomain: "juttame.firebaseapp.com",
  projectId: "juttame",
  storageBucket: "juttame.appspot.com",
  messagingSenderId: "638692346460",
  appId: "1:638692346460:web:7dfc403c8d8336dd87b499"
};


  export default firebaseConfig;