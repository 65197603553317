
import firebaseService from "./firebaseService";
const firebase = firebaseService();


import authenticationService from "./authenticationService";
const auth = authenticationService();


import { toRefs, reactive, watch } from "vue";


const state = reactive({
    notifications: [],
    numberOfUnreadNotifications: 0,
    initialized: false,
    initializing: false,
    
});

const unsubscribeHandles = [];


export default function () {

    function updateUnreadCount(){
        let count = 0;
        for (let notification of state.notifications) {        
          if (!notification.read) {            
            count ++
          }
        }        
        state.numberOfUnreadNotifications = count;
    }


    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        state.notifications = [];        

    }

    async function loadNotifications() {

        //clear any existing subscriptions
        if (unsubscribeHandles.length > 0) {
            await teardown()
        }


        if (!firebase.auth().currentUser || !firebase.auth().currentUser.uid) {
            console.log('skipping loadNotifications no user');        
            return;
        }

        let notificationsUnsubscribeHandle = await firebase
            .firestore()
            .collection("notifications")
            .where('userId', '==', firebase.auth().currentUser.uid)
            .orderBy("date", "desc")
            .onSnapshot((ref) => {
                ref.docChanges().forEach((change) => {
                    const { newIndex, oldIndex, doc, type } = change;
                    let data = doc.data();
                    data.id = doc.id;
                    if (type === "added") {
                        state.notifications.splice(newIndex, 0, data);
                        // if we want to handle references we would do it here
                    } else if (type === "modified") {
                        // remove the old one first
                        state.notifications.splice(oldIndex, 1);
                        // if we want to handle references we would have to unsubscribe
                        // from old references' listeners and subscribe to the new ones
                        state.notifications.splice(newIndex, 0, data);
                    } else if (type === "removed") {
                        state.notifications.splice(oldIndex, 1);
                        // if we want to handle references we need to unsubscribe
                        // from old references
                    }                    
                });
                updateUnreadCount()
            });

        unsubscribeHandles.push(notificationsUnsubscribeHandle);


    }


    async function markAllAsRead() {        
        if (!firebase.auth().currentUser) {
            return;
        }
        if(state.numberOfUnreadNotifications <= 0){
            return
        }
        for(let notification of state.notifications){
            await firebase.firestore().collection("notifications").doc(notification.id).update({
                read: true
            })
        }
    }
    

    if(!state.initialized && !state.initializing){
        state.initializing = true;
        
        watch(auth.user, async  (user,prevUser) => {        
            if(user){                
                loadNotifications();
            }
            if(!user){
                teardown();
            }
        })
                
        if(auth.user){
            loadNotifications();
        }
        state.initialized = true
        state.initializing = false        
    }

    return {
        ...toRefs(state),
        markAllAsRead,
        loadNotifications
    };

}
