<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" v-if="isIos">
          <ion-button @click="cancel">Cancel</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="cancel" v-if="!isIos">Cancel</ion-button>
          <ion-button color="primary" :disabled="numberOfGroupMembers < 1 && groupChatVisibility != 'public'" v-if="addGroupMode" @click="createGroup">Create</ion-button>
        </ion-buttons>
        <ion-title v-if="!addGroupMode">Start chatting...</ion-title>
        <ion-title v-if="addGroupMode"
          >New Chat Group
          <span class="member-count" v-if="numberOfGroupMembers && numberOfGroupMembers > 0">({{ numberOfGroupMembers }})</span></ion-title
        >
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ison-padding">
      <ion-list lines="full" >
        <ion-item v-if="!addGroupMode">
          <ion-button expand="full" fill="clear" @click="startGroupChat"
            ><ion-icon slot="start" :icon="chatbubblesOutline" class=""></ion-icon>Add Group ...</ion-button
          >
          <ion-button expand="full" fill="clear" @click="search"
            ><ion-icon slot="start" :icon="searchOutline" class=""></ion-icon>Search ...</ion-button
          >
        </ion-item>
        <ion-item v-if="addGroupMode">
          <div class="validation-errors" v-if="errors && errors.name">
            <span class="validation-error" v-for="error of errors.name" v-text="error" :key="error"></span>
          </div>
          <ion-label position="stacked">Group Name</ion-label>
          <ion-input
            name="name"
            type="text"
            placeholder=""
            ref="groupChatName"
            v-model="groupChatName"
            :class="{
              'has-errors': errors && (errors.name || errors.global),
            }"
          />
        </ion-item>
        <ion-item v-if="addGroupMode">
          <div class="validation-errors" v-if="errors && errors.visibility">
            <span class="validation-error" v-for="error of errors.visibility" v-text="error" :key="error"></span>
          </div>
          <ion-label>Visibility</ion-label>
          <ion-select :value="groupChatVisibility" @ionChange="visibilityChanged" >
            <ion-select-option value="private">private</ion-select-option>
            <ion-select-option value="public">public</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <ion-list lines="full">
        <ion-list-header v-if="!addGroupMode && hasContacts" lines="full"> <b>People you follow:</b> </ion-list-header>
        <div class="no-people-notice notice" v-if="!hasContacts">
          <ion-text
            >You don't follow anyone!<br />
            Start following some friends and you'll see them here.</ion-text
          >
          <ion-button @click="search"><ion-icon slot="start" :icon="searchOutline" class=""></ion-icon>Search People...</ion-button>
        </div>

        <chat-suggestion-item
          v-for="uid of suggestions"
          :key="uid"
          :uid="uid"
          @click="startChat(uid)"
          v-model="members[uid]"
          :show-checkbox="addGroupMode"
        ></chat-suggestion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script >
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonButton,
  IonLabel,
  IonIcon,
  IonModal,
  IonInput,
  IonTextarea,
  modalController,
  IonList,
  IonButtons,
  IonItem,
  IonText,
  IonSelect,
  IonSelectOption,
  IonListHeader,
} from "@ionic/vue";
import { chatbubblesOutline, searchOutline } from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import * as Validator from "validatorjs";
import authenticationService from "@/composables/authenticationService";
import userService from "../composables/userService";
import ChatSuggestionItem from "./ChatSuggestionItem";

export default {
  name: "AddChatModal",
  props: [],
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonAvatar,
    IonButton,
    IonLabel,
    IonIcon,
    IonModal,
    IonInput,
    IonTextarea,
    IonList,
    IonButtons,
    IonItem,
    IonText,
    IonListHeader,
    ChatSuggestionItem,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      reason: null,
      errors: null,
      modalIsReady: false,
      addGroupMode: false,
      members: {},
      groupChatName: null,
      groupChatVisibility: "public"//"private",
    };
  },
  setup() {
    const auth = authenticationService();
    const users = userService();
    return { auth, chatbubblesOutline, searchOutline, userService: users };
  },
  watch: {},
  async mounted() {
    //its bc a bug in ionic  https://github.com/ionic-team/ionic-framework/issues/21242
    setTimeout(() => {
      this.modalIsReady = true;
    }, 300);
    this.addGroupMode = false;
  },
  computed: {
    isIos() {
      return Capacitor.getPlatform() == "ios";
    },
    suggestions() {
      return this.userService.following && this.userService.following.value ? Object.keys(this.userService.following.value) : null;
    },
    hasContacts() {
      return this.suggestions && this.suggestions.length > 0;
    },
    numberOfGroupMembers() {
      //number of participants without me
      return this.participants.length - 1;
    },
    participants() {
      let participants = Object.keys(this.members).filter((uid) => this.members[uid]);
      participants.push(this.loggedInUser.uid);
      return participants;
    },
  },
  unmounted() {
    this.modalIsReady = false;
  },
  methods: {
    async createGroup() {
      if (this.numberOfGroupMembers <= 0 && this.groupChatVisibility !='public' ) {
        return;
      }

      let rules = {
        name: "required",
        visibility: "required",
      };
      let data = {
        name: this.groupChatName,
        visibility: this.groupChatVisibility,
        dateCreated: new Date(),
        participants: this.participants,
        ownerUid: this.loggedInUser.uid,
      };


      let validation = new Validator(data, rules);
      if (validation.fails()) {
        this.errors = validation.errors.errors;
        if (this.errors["name"]) {
          this.focusOnGroupName();
        }
        return;
      } else {
        this.errors = null;
      }

      console.log('data', data, this.loggedInUser.uid);
      let response = await this.firebase.firestore().collection("conversations").add(data);
      this.$router.push({
        name: "chat-conversation",
        params: { conversationId: response.id },
        replace: true,
      });
      modalController.dismiss();
    },
    startChat(uid) {
      if (this.addGroupMode) {
        return;
      }
      let conversationId = this.loggedInUser.uid > uid ? this.loggedInUser.uid + ":" + uid : uid + ":" + this.loggedInUser.uid;

      this.$router.push({
        name: "chat-conversation",
        params: { conversationId: conversationId },
        replace: true,
      });
      modalController.dismiss();
    },
    cancel() {
      modalController.dismiss();
    },
    startGroupChat() {
      // this.groupChatVisibility = this.hasContacts ? 'private': 'public';
      this.addGroupMode = true;
      this.focusOnGroupName();
    },
    focusOnGroupName() {
      this.$nextTick(() => {
        setTimeout(async () => {
          (await this.$refs.groupChatName.$el.getInputElement()).focus();
        }, 200);
      });
    },
    visibilityChanged(e) {
      let value = e.detail && e.detail.value ? e.detail.value : "private";
      if (this.groupChatVisibility != value) {
        this.groupChatVisibility = value;
      }
    },
    search() {
      this.$router.push({
        name: "search",
        replace: true,
      });
      modalController.dismiss();
    },
  },
};
</script>
<style scoped>
ion-item {
  --min-height: 0;
}
ion-item ion-button {
  flex: 1;
  min-height: 2rem;
}
ion-item ion-button::part(native) {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.no-people-notice {
  text-align: center;
  padding: 1rem;
}

.no-people-notice ion-text {
  line-height: 1.4;
  max-width: 500px;
  margin: 0 auto;
  display: block;
  color: #444;
  font-size: 1.15rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
</style>