<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script >
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import authenticationService from "@/composables/authenticationService";

import chatService from "./composables/chatService";
import postsService from "./composables/postsService";
import userService from "./composables/userService";
import notificationsService from "./composables/notificationsService";
var onlineStatusInterval = null;
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  unmounted() {
    clearInterval(onlineStatusInterval);
  },
  async mounted() {
    await this.auth.updateOnlineState(true);
    onlineStatusInterval = setInterval(async () => {
      await this.auth.updateOnlineState(true);
    }, 60 * 1000);
  },
  watch: {
    user() {
      if (this.user) {
        this.chat.loadChat();
      }
    },
  },
  setup() {
    const auth = authenticationService();
    let chat = chatService();
    let posts = postsService();
    let users = userService();
    let notifications = notificationsService();
    chat.loadChat();
    return { user: auth.user, chat, auth, posts, notificationsService: notifications,userService: users };
  },
});
</script>
<style>
.validation-errors.global {
  padding-left: 16px;
}
.validation-error {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  margin-bottom: -0.7rem;
}

ion-button ion-spinner {
  height: 1.2em;
}

@media (max-width: 500px) {
  ion-toolbar ion-title {
    font-size: 1.1rem !important;
  }
  ion-toolbar ion-buttons ion-button {
    font-size: 0.8rem !important;
  }
}

/*
add some margin to the bottom to avoid an overlap with focused yellow mac elements
*/
ion-label.label-stacked{
  margin-bottom: 0.3rem!important;
}

.firebase-emulator-warning{
  display: none;
}
</style>