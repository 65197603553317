import { toRefs, reactive } from "vue";

import firebaseService from "./firebaseService";
const firebase = firebaseService();

import axios from "axios";
import config from "../../config"
import * as Validator from "validatorjs";
import { Buffer } from "buffer/";

function getBuffer(file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.onload = (ev) => {
            resolve(ev.target.result);
        };
        reader.readAsArrayBuffer(file);
    });
}


export default function () {

    async function uploadFile(file, progressCallback) {

        let token = await firebase.auth().currentUser.getIdToken();

        console.log('file',file, typeof file)
        let fileData = null;
        if(file.data){
            fileData = Buffer.from(file.data)
        }else{
            fileData = await getBuffer(file);
        }

        let totalFileSize = Buffer.byteLength(fileData);

        console.log('fileData',fileData)
        console.log('totalFileSize',totalFileSize)



        let callbackWrapper = (progress,totalFileSize) =>{
            if(progressCallback){
                try {
                    progressCallback(progress,totalFileSize)
                    
                } catch (error) {
                    console.log('error calling progressCallback', error)                    
                }
            }

        }        


        try {
            let uploadLinkResponse = await axios.post(
                config.apiUrl + "/api/create-uploadlink/",
                { type: file.type },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                }
            )

            let uploadLink = uploadLinkResponse.data.url;
            let fileName = uploadLinkResponse.data.fileName;

            const axiosConfig = {
                onUploadProgress: progressEvent => {
                    console.log('progressEvent',progressEvent.loaded, totalFileSize)
                    if(progressEvent.loaded){
                        callbackWrapper(progressEvent.loaded/totalFileSize,totalFileSize);
                    }
                },
                headers: { "Content-Type": file.type } 
            }

            callbackWrapper(0,totalFileSize);
            let uploadResponse = await axios.put(uploadLink, fileData,axiosConfig);
            callbackWrapper(1,totalFileSize)

            console.log('uploadResponse', uploadResponse);
            return { type: file.type, name: fileName };

        } catch (error) {
            console.log("error@fileService.uploadFile", error);
            throw new Error("Error uploading file");
        }



    }

    return {
        uploadFile
    };
}
