import { toRefs, reactive } from "vue";
import {PushNotifications} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';




const state = reactive({
    token: null
});

export default function () {    

    const setup = (actionPerformedListener) => {

        const isAvailable = Capacitor.isPluginAvailable('PushNotifications');
        if(!isAvailable){
            console.log('PushNotifications are not available on this platform')
            return
        }

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        console.log('Requesting PushNotifications Permissions')


        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        console.log('PushNotifications Permissions denied', res)
                    }
                    else {
                        PushNotifications.register();
                    }
                });
            }
            else {
                PushNotifications.register();
            }
        });




        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                // console.log('Push registration success, token: ' + token.value);
                state.token = token.value;
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                state.token = null;
                // console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                // console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {            
            if(actionPerformedListener){
                actionPerformedListener(notification)
            }
        });
    }

    const clearAllNotifications = () => {
        if (Capacitor.getPlatform() !== 'web') {
            PushNotifications.removeAllDeliveredNotifications();
        }
    }


    return {
        ...toRefs(state),        
        setup,
        clearAllNotifications
    }


}