<template >
  <ion-avatar class="profile-avatar" @click="zoomIntoImage">
    <img :src="profilePictureUrl" />
    <span class="online-marker" v-if="isOnline && showStatus"></span>
  </ion-avatar>
</template>

<script >
import { IonIcon, IonAvatar, modalController } from "@ionic/vue";
import mediaService from "../composables/mediaService";
import ImageViewModal from "./ImageViewModal.vue";

var updateInterval;
const unsubscribeHandles = [];

export default {
  name: "UserAvatar",
  props: {
    user: Object,
    showStatus: Boolean,
    zoom: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IonIcon,
    IonAvatar,
  },
  data() {
    return {
      isOnline: false,
      userActivity: null,
      profilePictureUrl: "icons/avatar.svg",
    };
  },
  watch: {
    userActivity: {
      deep: true,
      handler: function () {
        this.updateStatus();
      },
    },
    user: function (user, prevUser) {
      if ((!user && prevUser) || (user && !prevUser) || user.picture != prevUser.picture) {
        this.updateProfilePicture();
        this.startWatchingActivity();
      }
    },
  },
  async unmounted() {
    if (updateInterval) {
      clearInterval(updateInterval);
    }
    for (let unsubscribeHandle of unsubscribeHandles) {
      await unsubscribeHandle();
    }
    //empty it
    unsubscribeHandles.length = 0;
  },
  async mounted() {
    if (this.user) {
      this.updateProfilePicture();
      this.startWatchingActivity();
    }
  },

  computed: {},
  methods: {
    async startWatchingActivity() {
      if (updateInterval) {
        clearInterval(updateInterval);
      }
      for (let unsubscribeHandle of unsubscribeHandles) {
        await unsubscribeHandle();
      }
      if (this.showStatus) {
        let unsubscribeHandle = await this.firebase
          .firestore()
          .collection("activity")
          .doc(this.user.uid)
          .onSnapshot((snapshot) => {
            this.userActivity = snapshot.data();
          });
        unsubscribeHandles.push(unsubscribeHandle);
        updateInterval = setInterval(this.updateStatus, 30 * 1000);
      }
    },
    async zoomIntoImage() {
      if (!this.zoom) {
        return;
      }
      const modal = await modalController.create({
        component: ImageViewModal,
        componentProps: {
          image: this.profilePictureUrl,
        },
        cssClass: "profile-image-modal",
      });

      return modal.present();
    },
    async updateProfilePicture() {
      if (this.user && this.user.picture) {
        this.profilePictureUrl = await this.mediaService.getMediaUrl(this.user.picture);
      } else {
        this.profilePictureUrl = "icons/avatar.svg";
      }
    },
    updateStatus() {
      if (!this.userActivity || !this.userActivity.lastTimeOnline) {
        this.isOnline = false;
        return;
      }
      let date = new Date(this.userActivity.lastTimeOnline.seconds * 1000);
      let secondsSinceLastSeen = (new Date() - date) / 1000;
      this.isOnline = secondsSinceLastSeen <= 60;
    },
  },
  setup() {
    let media = mediaService();
    return { mediaService: media };
  },
};
</script>
<style scoped>
.profile-avatar {
  position: relative;
}
.online-marker {
  background-color: var(--ion-color-success);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.5rem;
  position: absolute;
  right: 0%;
  bottom: 0%;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
</style>>
