<template>
  <ion-item class="conversation-list-item" @click="startChat" v-if="shouldBeVisible" lines="full">
    <user-avatar
      slot="start"
      v-if="!(conversation && conversation.isGroupChat) && participants && participants.length >= 1"
      class="profile-image"
      :user="participants[0]"
      :show-status="true"
    ></user-avatar>
    <ion-icon slot="start" v-if="conversation && conversation.isGroupChat" class="groupchat-image" :icon="peopleCircleOutline"></ion-icon>

    <ion-label>
      <h2 class="conversation-title" v-text="title"></h2>
      <h3 class="message-preview" v-if="messagePreview" v-text="messagePreview"></h3>
      <h3 class="participantsInfo" v-if="!messagePreview && conversation && conversation.isGroupChat" v-text="participantsInfo"></h3>
    </ion-label>

    <ion-buttons slot="end">
      <span class="number-of-unread-messages" v-if="unreadCount > 0" v-text="unreadCount"></span>
      <ion-button class="view-button" @click="startChat" v-if="!hideActions">
        <ion-icon slot="icon-only" :icon="chevronForwardOutline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
</template>

<script >
import { IonIcon, IonAvatar, IonLabel, IonItem, IonButton, IonButtons } from "@ionic/vue";

import { chatbubbleOutline, chevronForwardOutline, peopleCircleOutline } from "ionicons/icons";
import userService from "../composables/userService";
import UserAvatar from "./UserAvatar";
var unsubscribeHandle = null;
export default {
  name: "ConversationListItem",
  props: ["uid", "conversation", "hideActions"],
  components: {
    IonIcon,
    IonAvatar,
    IonLabel,
    IonItem,
    IonButton,
    IonButtons,
    UserAvatar,
  },
  data() {
    return {
      participants: [],
      me: null
    };
  },
  unmounted() {
    if (unsubscribeHandle) {
      //   unsubscribeHandle();
      unsubscribeHandle = null;
    }
  },
  watch: {
    conversation: {
      deep: true,
      handler: function (conversation, prevConversation) {        
        this.getParticipants();
      },
    },
  },
  mounted() {
    this.getParticipants();
  },
  computed: {
    shouldBeVisible() {
      if (this.participants) {
        if (this.participants.length == 1) {
          if (this.userService.blockedUser(this.participants[0])) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    title() {
      if (this.conversation && this.conversation.name) {
        return this.conversation.name;
      }
      let combinedTitle = "";
      if (this.participants) {
        for (let participant of this.participants) {
          if (combinedTitle.length > 0) {
            combinedTitle += ", ";
          }
          combinedTitle += participant.name;
        }
      }
      return combinedTitle;
    },
    messagePreview() {
      return this.conversation && this.conversation.preview ? this.conversation.preview : null;
    },
    iAmParticipant() {
      if(!this.loggedInUser){
        return false;
      }
      if(this.conversation && this.conversation.id && this.conversation.id.includes(':')){
        return this.conversation.id.includes(this.loggedInUser.uid)
      }
      return this.conversation && this.conversation.participants && this.conversation.participants.includes(this.loggedInUser.uid);
    },
   participantsInfo() {
      let members = [];
      if (this.iAmParticipant && this.me) {        
        members.push(this.me);
      }
      members = members.concat(this.participants);

      let maxNames = 2;
      let combinedTitle = "";
        
      for (let i = 0; i < members.length; i++) {
        let member = members[i];
        let name = this.loggedInUser && member.uid == this.loggedInUser.uid ? "You": member.name ;
        

        if (i == maxNames   && members.length > maxNames) {
          let numberOfRemainingUsers = members.length - maxNames;
          if(numberOfRemainingUsers > 1){
            name = "" + numberOfRemainingUsers + " others";
          }
        }

        combinedTitle += name;

        //if thats the last one break
        if (i >= members.length - 1 || i >= maxNames) {
          break;
        }

        if (i == members.length - 2 || i == maxNames - 1) {
          combinedTitle += " and ";
        } else {
          combinedTitle += ", ";
        }
      }

      return combinedTitle;
    },
    unreadCount() {
      if (this.conversation && this.conversation.unreadCounts && this.loggedInUser && this.conversation.unreadCounts[this.loggedInUser.uid]) {
        return this.conversation.unreadCounts[this.loggedInUser.uid];
      }
      return 0;
    },
  },
  methods: {
    async getParticipants() {
      this.me = await this.userService.getUser(this.loggedInUser.uid)        
      this.participants = [];

      if (this.conversation && this.conversation.participants) {
        for (let participant of this.conversation.participants) {
          if (participant != this.loggedInUser.uid) {
            this.participants.push(await this.userService.getUser(participant));
          }
        }
      } else if (this.conversation && this.conversation.id && this.conversation.id.includes(":")) {
        let participantUids = this.conversation.id.split(":");
        for (let participantUid of participantUids) {
          if (participantUid != this.loggedInUser.uid) {
            this.participants.push(await this.userService.getUser(participantUid));
          }
        }
      }
    },
    startChat() {
      console.log('startChat', this.conversation.id )
      this.$router.push({
        name: "chat-conversation",
        params: { conversationId: this.conversation.id },
        replace: true,
      });
    },
  },
  setup() {
    let userServiceInstance = userService();
    return {
      userService: userServiceInstance,
      chatbubbleOutline,
      chevronForwardOutline,
      peopleCircleOutline,
    };
  },
};
</script>
<style scoped>
.message-preview {
  color: #666;
}


.conversation-title {
  font-weight: bold;
}
.number-of-unread-messages {
  background-color: var(--ion-color-secondary);
  border-radius: 50%;
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.05);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--ion-color-secondary-contrast);
}

ion-icon.groupchat-image {
  font-size: 40px;
  margin-right: 16px;
}
</style>