import { Capacitor } from '@capacitor/core';

let options = {
  url: "https://www.juttame.com",
  apiUrl: "https://www.juttame.com",
  cdnUrl: "https://www.juttame.com",
  region: "europe-west1",
  translations:{
    languages: {
      available: ['de','en','es'],
      default: 'de',
    }  
  }
  
};

// if (Capacitor.getPlatform() === 'web' && location.hostname === 'localhost') {
//   options.url = "http://localhost:5000";
//   options.apiUrl = "http://localhost:5000";
//   console.log('using localhost as apiUrl and url', options.apiUrl, options.url )
// }


export default options;