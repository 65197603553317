import fileServiceModule from "./fileService";

import firebaseService from "./firebaseService";
import firebase from 'firebase/compat/app'
const firebaseApp = firebaseService();

import authenticationService from "./authenticationService";
const auth = authenticationService();

import * as Validator from "validatorjs";

import { toRefs, reactive, watch } from "vue";

const fileService = fileServiceModule();

const state = reactive({
    posts: [],
    ownPosts: [],
    initializing: false,
    initialized: false,
    lastTimeRequestedUpdate: null,
    loadingPosts: false,
    lastVisibleDoc: null,
    reachedEnd: false,


});

const unsubscribeHandles = [];


export default function () {

    async function teardown() {
        for (let unsubscribeHandle of unsubscribeHandles) {
            await unsubscribeHandle();
        }
        //empty it
        unsubscribeHandles.length = 0;
        state.posts = [];
        state.ownPosts = [];

    }

    function getOwnPost(postId) {
        for (let post of state.posts) {
            if (post.id == postId) {
                return post
            }
        }
        return null;
    }



    async function requestUpdate() {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }
        let now = new Date();

        if (this.lastTimeRequestedUpdate && (now - this.lastTimeRequestedUpdate) < 60000) {
            console.log('to early to request new update')
            return;
        }

        await firebaseApp
            .firestore()
            .collection("userfeed-requests")
            .doc(firebaseApp.auth().currentUser.uid).
            set({ requested: now }, { merge: true })
    }


    async function loadMorePosts() {


        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (state.loadingPosts) {            
            return;
        }

        if (state.reachedEnd) {
            return;
        }


        state.loadingPosts = true;
        let query = null;

        if (state.lastVisibleDoc) {
            query = firebaseApp
                .firestore()
                .collection("userfeeds")
                .where('processed', '==', true)
                .where('uid', '==', firebaseApp.auth().currentUser.uid)
                .orderBy("date", "desc")
                .startAfter(state.lastVisibleDoc)
                .limit(12)
        } else {
            query = firebaseApp
                .firestore()
                .collection("userfeeds")
                .where('processed', '==', true)
                .where('uid', '==', firebaseApp.auth().currentUser.uid)
                .orderBy("date", "desc")
                .limit(12)
        }

        let snap = await query.get()

        let numberOfLoadedPosts = snap.size;
        if (numberOfLoadedPosts > 0) {

            for (let doc of snap.docs) {
                let data = doc.data();
                data.userfeedId = doc.id;
                data.id = data.postId;
                state.posts.push(data);
            }
            state.lastVisibleDoc = snap.docs[snap.docs.length - 1]
        }

        state.loadingPosts = false;
        if (numberOfLoadedPosts < 1) {
            state.reachedEnd = true;
        }

        return numberOfLoadedPosts
    }




    async function loadPosts() {


        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }


        //load the initial batch
        await loadMorePosts();
        
        let unsubscribeHandle = await firebaseApp
            .firestore()
            .collection("userfeeds")
            .where('processed', '==', true)
            .where('uid', '==', firebaseApp.auth().currentUser.uid)
            .where('dateAddedToFeed', '>=', new Date())
            .orderBy("dateAddedToFeed", "asc")
            .onSnapshot((ref) => {
                ref.docChanges().forEach((change) => {
                    const { newIndex, oldIndex, doc, type } = change;
                    let data = doc.data();
                    data.userfeedId = doc.id;
                    data.id = data.postId;
                    if (type === "added") {
                        state.posts.splice(0, 0, data);
                    }
                    //     // if we want to handle references we would do it here
                    // } else if (type === "modified") {
                    //     // remove the old one first
                    //     state.posts.splice(oldIndex, 1);
                    //     // if we want to handle references we would have to unsubscribe
                    //     // from old references' listeners and subscribe to the new ones
                    //     state.posts.splice(newIndex, 0, data);
                    // } else if (type === "removed") {
                    //     state.posts.splice(oldIndex, 1);
                    //     // if we want to handle references we need to unsubscribe
                    //     // from old references
                    // }
                });
            });
        unsubscribeHandles.push(unsubscribeHandle);
    }



    async function loadOwnPosts() {



        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        let unsubscribeHandle = await firebaseApp
            .firestore()
            .collection("posts")
            .where('ownerUid', '==', firebaseApp.auth().currentUser.uid)
            .where('error', '==', false)
            .orderBy("date", "desc")
            .onSnapshot((ref) => {
                ref.docChanges().forEach((change) => {
                    const { newIndex, oldIndex, doc, type } = change;
                    let data = doc.data();
                    data.id = doc.id;
                    if (type === "added") {
                        state.ownPosts.splice(newIndex, 0, data);
                        // if we want to handle references we would do it here
                    } else if (type === "modified") {
                        // remove the old one first
                        state.ownPosts.splice(oldIndex, 1);
                        // if we want to handle references we would have to unsubscribe
                        // from old references' listeners and subscribe to the new ones
                        state.ownPosts.splice(newIndex, 0, data);
                    } else if (type === "removed") {
                        state.ownPosts.splice(oldIndex, 1);
                        // if we want to handle references we need to unsubscribe
                        // from old references
                    }
                });
            });

        unsubscribeHandles.push(unsubscribeHandle);

    }


    async function addPost(data, progressCallback) {
        if (!firebaseApp.auth().currentUser) {
            return;
        }
        

        let messageRules = {

        }
        let validation = new Validator(data, messageRules);
        if (validation.fails()) {
            throw new Error({
                type: 'validation',
                errors: validation.errors.errors
            })
        }

        if (data.picture) {
            data.picture = await fileService.uploadFile(data.picture, progressCallback);
        }

        if (data.video) {
            data.video = await fileService.uploadFile(data.video, progressCallback);
        }

        data.senderUid = firebaseApp.auth().currentUser.uid;

        await firebaseApp.firestore().collection("posts").doc(data.id).set(data);
    }

    async function reportPost(postId, reason) {
        if (!firebaseApp.auth().currentUser) {
            return;
        }        
        await firebaseApp.firestore().collection("reported-posts").add({
            reportingUser: firebaseApp.auth().currentUser.uid,
            reason: reason,
            postId: postId
        });
    }

    async function deletePost(postId) {
        if (!firebaseApp.auth().currentUser) {
            return;
        }
        console.log('deleting post ', postId)
        await firebaseApp.firestore().collection("posts").doc(postId).delete();
        let indexToDelete = 0

        //delete item from postfeed
        for (let post of state.posts) {
            if (post.id == postId) {
                state.posts.splice(indexToDelete, 1)
                break
            }
            indexToDelete++
        }

    }

    if (!state.initialized && !state.initializing) {

        state.initializing = true;


        watch(auth.user, async (user, prevUser) => {
            console.log('user changed post service', user);
            teardown();

            if (user) {
                loadOwnPosts()
                loadPosts()
            }
        })


        if (auth.user) {
            loadOwnPosts()
            loadPosts()
        }

        state.initialized = true
        state.initializing = false

    }


    async function likePost(post) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!post || !post.id) {
            console.log("likePost: no post given")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("likes")
            .doc(firebaseApp.auth().currentUser.uid).
            set({ [post.id]: true }, { merge: true })
    }

    async function unlikePost(post) {
        if (!firebaseApp.auth().currentUser || !firebaseApp.auth().currentUser.uid) {
            return;
        }

        if (!post || !post.id) {
            console.log("unlikePost: no post given")
            return;
        }

        await firebaseApp
            .firestore()
            .collection("likes")
            .doc(firebaseApp.auth().currentUser.uid)
            .update({
                [post.id]: firebase.firestore.FieldValue.delete()
            });
    }



    return {
        ...toRefs(state),
        loadPosts,
        loadOwnPosts,
        addPost,
        teardown,
        deletePost,
        getOwnPost,
        likePost,
        unlikePost,
        requestUpdate,
        loadMorePosts,
        reportPost

    };

}
